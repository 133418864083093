import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { CmsVideo, Props as CmsVideoProps } from "components/CmsVideo";
import {
    BackgroundColor,
    TextColor,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { CmsImage, StreamfieldBlock } from "interfaces";

import { Props as ResponsiveImageProps } from "../ResponsiveImage";

export type CmsVideoPaths = {
    file: string;
    url: string;
    poster: string | null;
};

export interface CmsMedia {
    image: CmsImage | null;
    video: CmsVideoPaths | null;
}

export interface CmsHero {
    content?: string;
    media: CmsMedia;
}

export type ImageBackgroundBlock = StreamfieldBlock<
    "image_background_hero",
    CmsHero
>;

export type CmsHeroBlock = ImageBackgroundBlock;

export type BackgroundColorChoice =
    | "navy"
    | "smoke"
    | "satin_brass"
    | "pewter1"
    | "pewter2"
    | "gold"
    | "green"
    | "blue"
    | "pink"
    | "beige"
    | "darkSilver"
    | "transparent";

export const heroColorSettings: (color: BackgroundColorChoice) => {
    backgroundColor: BackgroundColor;
    textColor: TextColor;
} = (color) => {
    switch (color) {
        case "navy":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "smoke":
            return {
                backgroundColor: "color2",
                textColor: "light",
            };
        case "satin_brass":
            return {
                backgroundColor: "color3",
                textColor: "inherit",
            };
        case "pewter1":
            return {
                backgroundColor: "color4",
                textColor: "inherit",
            };
        case "pewter2":
            return {
                backgroundColor: "color5",
                textColor: "inherit",
            };
        case "gold":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "green":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "blue":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "pink":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "beige":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "darkSilver":
            return {
                backgroundColor: "color1",
                textColor: "light",
            };
        case "transparent":
            return {
                backgroundColor: "transparent",
                textColor: "inherit",
            };
    }
};

export interface HeroMediaProps {
    media: CmsMedia;
    imageProps: Omit<ResponsiveImageProps, "cldId"> | null;
    videoProps: Omit<Omit<CmsVideoProps, "file">, "poster"> | null;
}

/**
 * Used in all heroes to allow the possibility of images or videos everywhere. It requires a media
 * source URL and configuration props in order to show something, and if everything is present, it
 * favors images over video.
 *
 * The config props should be set up in code, and the media sources should come from the CMS.
 */
export const HeroMedia = ({
    media,
    imageProps,
    videoProps,
}: HeroMediaProps) => {
    if (!!media.image && !!imageProps) {
        return <CmsResponsiveImage cmsImage={media.image} {...imageProps} />;
    }
    if (!!media.video && !!videoProps) {
        const video = media.video;
        return (
            <CmsVideo
                {...videoProps}
                file={video.file}
                poster={video.poster ? video.poster : undefined}
            />
        );
    }
    return <></>;
};
